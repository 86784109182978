/* This example requires Tailwind CSS v2.0+ */
import React from 'react'

import useTranslations from "../hooks/useTranslations"

export default function ThankYouPage() {
  const { thankYouPage } = useTranslations()

  return (
    <>
      <div className="px-6 py-16 mx-auto max-w-7xl md:pt-28">
        <h1 className="mb-4 text-xl md:text-4xl md:mb-6">{thankYouPage.title}</h1>
        <p className="max-w-md text-sm text-gray-500">{thankYouPage.text}</p>
      </div>
    </>
  )
}